<template>
  <div class="update-humbnail">
    
    <div class="page-header">
      <h3 class="page-title">Update Thumbnail</h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb d-none-sm">
          <li class="breadcrumb-item">
            <a href="javascript:void(0);">Media </a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Update Thumbnail
          </li>
        </ol>
      </nav>
    </div>
    <div class="row">
      <div class="col-md-4 col-lg-4 mb-3">
        <div class="card">
          <div class="card-header" style="background: #fff">Document</div>
          <vue-dropzone
            ref="myVueDropzone"
            id="thumbDocument"
            :options="thumbDocument"
            :useCustomSlot="true"
            v-on:vdropzone-success="uploadSuccess"
          >
            <div class="dropzone-custom-content">
              <div
                v-for="item in info"
                :key="item.id"
                class="image-size-dropzone"
              >
                <div v-if="item.id == 1">
                  <img
                    :src="`${imageURL}/${item.thumb_m}`"
                    class="show-img img-fluid"
                  />
                </div>
              </div>
              <div class="subtitle pt-3">
                <span>Change</span>
                <span style="color: #eb2027"> Thumbnail</span>
              </div>
            </div>
          </vue-dropzone>
        </div>
      </div>
      <div class="col-md-4 col-lg-4 mb-3">
        <div class="card">
          <div class="card-header" style="background: #fff">Video</div>
          <vue-dropzone
            ref="myVueDropzone"
            id="thumbVideo "
            :options="thumbVideo"
            :useCustomSlot="true"
            v-on:vdropzone-success="uploadSuccess"
          >
            <div class="dropzone-custom-content">
              <div v-for="item in info" :key="item.id">
                <div v-if="item.id == 2" class="image-size-dropzone">
                  <img
                    :src="`${imageURL}/${item.thumb_m}`"
                    class="show-img img-fluid"
                  />
                </div>
              </div>
              <div class="subtitle pt-3">
                <span>Change</span>
                <span style="color: #eb2027"> Thumbnail</span>
              </div>
            </div>
          </vue-dropzone>
        </div>
      </div>
      <div class="col-md-4 col-lg-4 mb-3">
        <div class="card">
          <div class="card-header" style="background: #fff">Audio</div>
          <vue-dropzone
            ref="myVueDropzone"
            id="thumbAudio "
            :options="thumbAudio"
            :useCustomSlot="true"
            v-on:vdropzone-success="uploadSuccess"
          >
            <div class="dropzone-custom-content">
              <div v-for="item in info" :key="item.id">
                <div v-if="item.id == 3" class="image-size-dropzone">
                  <img
                    :src="`${imageURL}/${item.thumb_m}`"
                    class="show-img img-fluid"
                  />
                </div>
              </div>
              <div class="subtitle pt-3">
                <span>Change</span>
                <span style="color: #eb2027"> Thumbnail</span>
              </div>
            </div>
          </vue-dropzone>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
const mediaApiURL = process.env.VUE_APP_MEDIA_SERVICE;
const imageURL = process.env.VUE_APP_IMAGE_URL;
import { mediaApi } from "../../api";
export default {
  name: "update-thumbnail",
  components: {
    vueDropzone: vue2Dropzone,
    
  },

  data() {
    return {
      imageURL,
      info: null,
      
      thumbDocument: {
        url: `${mediaApiURL}/thumb/update/document`,
      },
      thumbVideo: {
        url: `${mediaApiURL}/thumb/update/video`,
      },
      thumbAudio: {
        url: `${mediaApiURL}/thumb/update/audio`,
      },
    };
  },
  mounted() {
    this.list();
  },
  methods: {
    async list() {
      
      const { data } = await mediaApi.thumbnail();
      this.info = data.data.data;
      
    },
    uploadSuccess(response) {
      if (response.status == "success") {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: " ",
          text: "Updated successfully",
        });
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: "The given data was invalid",
        });
      }
    },
  },
};
</script>